export default [
	{
		title: 'Home',
		route: 'home',
		icon: 'HomeIcon',
		id: 0,
	},
	{
		title: 'User Management',
		route: 'admin-management',
		icon: 'UserIcon',
		id: 1,
	},
	{
		title: 'Role Management',
		route: 'role-management',
		icon: 'UserCheckIcon',
		id: 2,
		children: [
			{
				title: 'Role Management',
				route: 'role-management',
			},
			{
				title: 'Category Manager',
				route: 'category-management',
			},
		],
	},
	{
		title: 'Vendor Management',
		route: 'vendor-management',
		icon: 'UserIcon',
		id: 3,
		children: [
			{
				title: 'Vendors',
				route: 'vendors',
			},
			{
				title: 'Applications',
				route: 'applications',
			},
		],
	},
	{
		title: 'Products',
		route: 'pending-products',
		icon: 'ShoppingCartIcon',
		id: 4,
		children: [
			{
				title: 'Pending Products',
				route: 'pending-products',
			},
			{
				title: 'Approved Products',
				route: 'products',
			},
			{
				title: 'Addons',
				route: 'addons',
			},
		],
	},
	{
		title: 'Customers',
		route: 'customers',
		icon: 'UsersIcon',
		id: 5,
	},
	{
		title: 'Orders',
		route: 'orders',
		icon: 'ShoppingCartIcon',
		id: 6,
	},
	{
		title: 'Analytics',
		route: 'analytics',
		icon: 'PieChartIcon',
		id: 7,
	},
	{
		title: 'Notification Module',
		route: 'notification',
		icon: 'BellIcon',
		id: 8,
	},
	{
		title: 'Parameters',
		route: 'parameters',
		icon: 'SettingsIcon',
		id: 9,
		children: [
			{
				title: 'Regions',
				route: 'regions',
			},
			{
				title: 'Categories',
				route: 'categories',
			},
			{
				title: 'Attributes',
				route: 'attributes',
			},
		],
	},
];
